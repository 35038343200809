import React, { useEffect, useState } from "react"
import styled from "styled-components"
import StickyBtn from "./StickyBtn"
import Cookie from "universal-cookie"
import { throttled, trackButtonClick } from "../../util/functions"
import { PracticeOverlay } from "./PracticeOverlay"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
const cookies = new Cookie()

interface IStickyBtnsContainer {
  btn1Text?: string
  btn1Url?: string
  btn2Text?: string
  btn2Url?: string
  cookieClosed?: boolean
}
const StickyBtnsContainer = ({
  btn1Text,
  btn1Url,
  btn2Text,
  btn2Url,
  cookieClosed,
}: IStickyBtnsContainer) => {
  const [isOpen, setIsOpen] = useState(false)

  const getCookiePosition = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const container: HTMLElement | null = document.getElementById(
        "btns-sticky-container"
      )
      if (window.pageYOffset > 300) {
        if (container) {
          if (!cookies.get("cookiesAccepted")) {
            if (window.innerWidth <= 767) {
              container.style.cssText =
                "bottom: 5.3rem; opacity: 1; transition: bottom 1s ease-in;"
            } else {
              container.style.cssText =
                "bottom: 12.5rem; opacity: 1; transition: bottom 1s ease-in; "
            }
          } else {
            if (window.innerWidth <= 767) {
              container.style.cssText =
                "bottom: 10px; opacity: 1; transition: bottom 1s ease-in; "
            } else {
              container.style.cssText =
                "bottom: 2.5rem; opacity: 1; transition: bottom 1s ease-in; "
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    const eventListener = throttled(100, getCookiePosition)
    window.addEventListener("scroll", eventListener)

    return () => {
      window.removeEventListener("scroll", eventListener)
    }
  }, [])

  useEffect(() => {
    if (cookieClosed) {
      getCookiePosition()
    }
  }, [cookieClosed])

const handleOnClick = () => {
  trackButtonClick("Sticky Buttons: Book Appointment"); 
  setIsOpen(!isOpen);    
  const targetElement = document.querySelector('#modal-scroll-lock-selector');
  if(targetElement === null) return
  disableBodyScroll(targetElement);
}
  return (
    <Container id="btns-sticky-container">
      <PracticeOverlay isOpen={isOpen} setIsOpen={setIsOpen} href={btn1Url}/>
      {btn1Text && (
        <StickyBtn
          onClick={handleOnClick}
          // href={btn1Url}
          text={btn1Text}
          target
        />
      )}

      {btn2Text && <StickyBtn href={btn2Url} text={btn2Text} secondary />}
    </Container>
  )
}

export default StickyBtnsContainer

const Container = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  opacity: 0;
  bottom: -100%;
  right: 5%;
  z-index: 9999999;
  border-radius: 100px;
  @media (max-width: 1199px) {
    right: 3%;
  }
  @media (max-width: 767px) {
    width: 100%;
    right: 0;
    padding: 0 9px;
  }
`
