export const color: any = {
  brand: {
    blue: "#061b30",
    green: "#49DDC2",
    blueLight: "#37a1ce",
    orange: "#d84a34",
    grey: "#333333",
    white: "#ffffff",
    orangeActive: "#9F371D",
  },
  background: {
    blueLight: "rgba(80,227,194,0.25)",
    grey: "#f4f4f4",
    lightGrey: "#fafafa",
    white: "white",
    blueBackground: "#f1fdfa",
    blueCookie: "#d9f7ff",
    lightGreen: "#ECFBF8",
    tableGreen: "rgba(80, 227, 194, 0.5)",
    green10: "rgba(80, 227, 194, 0.1)",
    green20: "rgba(80, 227, 194, 0.2)",
    tableHighlight: "rgba(80, 227, 194, 0.05)",
  },
  text: {
    primary: "#000000",
    secondary: "#061B30",
    green: "#49DDC2",
    legal: "#999999",
    grey: "#5d6770",
    formBlack: "#0D0802",
    link: "#177d6a",
  },
  brandLight: {
    blue100: "#f5fcff",
    grey: "#e2e2e2",
    blue45: "#37a1ce",
  },
  support: {
    red: "#FF0002",
    green: "#4f9f31",
    yellow: "#f8e71c",
    radioBtnHover: "rgba(6,27,48,0.8)",
  },
  keyline: {
    grey: "#e5e5e5",
    lineGreen: "#02ae86",
    black: "#101820",
    treatmentHeader: "#35485b",
    nav: "rgba(230,230,230,.3)",
    green: "#1C2F42",
  },
}

export default color
