import moment from "moment"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

export const numberWithCommas = (x: number): string => {
  if (typeof x === "number") {
    if (x % 1 !== 0) {
      return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
  return x
}
export const numberWithCommasWithoutDecimals = (x: number): string => {
  if (typeof x === "number") {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return x
}
export const getPracticeEmail = (practice: string): string => {
  switch (practice) {
    case "West Wickham":
      return "ww@whiteandcodental.co.uk"
      // return "westwickham@whiteandcodental.co.uk"
      // return "jo@whiteandcodental.co.uk"
    case "Battersea Power Station":
      return "power@whiteandcodental.co.uk"
      // return "jo@whiteandcodental.co.uk"
    default:
    // return "dean@tectamers.co.uk"  
    return "rise@whiteandcodental.co.uk"
      //  , rosie@whiteandcodental.co.uk"
      // return "jo@whiteandcodental.co.uk"
  }
}

export const trackButtonClick = (category: string): void => {
  trackCustomEvent({
    category,
    action: "Click",
    label: window.location.href,
  })
}

export const trackWhatsApp = () => {
  trackCustomEvent({
    category: "WhatsApp Click",
    action: "Click",
    label: window.location.href,
  })
}

export function throttled(delay: number, fn: any) {
  let lastCall: number = 0
  return function(...args) {
    const now = new Date().getTime()
    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return fn(...args)
  }
}

export const getItem = (property: string): string | null => {
  if (typeof window !== "undefined") {
    return window.sessionStorage.getItem(property) || ""
  }
  return ""
}

export const setItem = (property: string, value: string): void => {
  if (typeof window !== "undefined") {
    window.sessionStorage.setItem(property, value)
  }
}

export const isInViewport = (elem: HTMLElement) => {
  let distance = elem.getBoundingClientRect()
  return (
    distance.top >= 0 &&
    distance.left >= 0 &&
    distance.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    distance.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  )
}
export const isInViewportFast = (elem: HTMLElement) => {
  let rect = elem.getBoundingClientRect()
  return (
    (rect.top <= 0 && rect.bottom >= 0) ||
    (rect.bottom >=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.top <=
        (window.innerHeight || document.documentElement.clientHeight)) ||
    (rect.top >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight))
  )
}
export const validateDateOfBirth = (val: string | number) => {
  if (typeof val !== "string") {
    return false
  }

  if (moment(val, "DD/MM/YY", true).isValid()) {
    return true
  }
}
export const validateDate = (val: string | number) => {
  if (typeof val !== "string") {
    return false
  }

  if (moment(val, "DD/MM/YYYY", true).isValid()) {
    return true
  }
}
export const renderDate = (
  date: string | number,
  limit: number = 8
): string => {
  let arr: Array<number | string> = date.toString().split("")
  arr = arr.filter((val, i) => {
    return val !== "/" && !isNaN(Number(val)) && i < limit
  })

  if (arr.length >= 3) {
    arr.splice(2, 0, "/")
  }

  if (arr.length >= 6) {
    arr.splice(5, 0, "/")
  }

  return arr.join("")
}

export const get = (p: any, o: string, d: any = null): any =>
  p.reduce((xs: string, x: number) => (xs && xs[x] ? xs[x] : d), o)

export const scrollTo = async (id: string) => {
  const element = document.getElementById(id)
  if (element && element.scrollIntoView) {
    element.scrollIntoView({ behavior: "smooth" })
  }
}

export const formatLink = (link: string = ""): string | null => {
  if (link) {
    const internalLink = link.split("internal:")

    if (internalLink.length > 1) {
      const prefix: string = process.env.BASE === "/" ? "" : process.env.BASE
      return prefix + internalLink[1]
    } else {
      return link
    }
  }
  return null
}

export const scrollPage = (id: string) => {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }
}

export const validateEmail = (email: string): boolean => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validatePhoneNumber = (phone: string | number): boolean => {
  const re = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
  return re.test(phone.toString())
}

export const validatePostcode = (postcode: string): boolean => {
  const re = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/
  return re.test(postcode)
}

export const validateOnBlur = (
  isValidated: boolean,
  toggleError: any
): void => {
  if (isValidated) {
    toggleError(false)
  } else {
    toggleError(true)
  }
}
