import StickyButtons from "./StickyButtonsContainer"
import React from "react"
import { formatLink } from "../../util/functions"
import { graphql, useStaticQuery } from "gatsby"

const StickyButtonsDrupal = ({ cookieClosed }) => {
  const data: any = useStaticQuery(graphql`
    query stickyButtons {
      nodeStickyButtons {
        firstButton: field_first_button {
          title
          uri
        }
        secondButton: field_second_button {
          title
          uri
        }
      }
    }
  `)

  const values = data.nodeStickyButtons
  const { firstButton, secondButton } = values

  return (
    <StickyButtons
      btn1Text={firstButton.title}
      btn1Url={formatLink(firstButton.uri)}
      btn2Text={secondButton.title}
      btn2Url={formatLink(secondButton.uri)}
      cookieClosed={cookieClosed}
    />
  )
}

export default StickyButtonsDrupal
